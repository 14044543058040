import styled from 'styled-components';

export const Bar = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    padding: 0px 8px;
    max-width: fit-content;
`;

export const LinePadding = styled.div`
    display: flex;
    padding-right: 0px;
    padding-left: 0px;
    margin: 0 8px;
    padding-top: 8px;
    align-items: center;
    width: 37px;
    ${({ theme }) => `
        ${
            theme.mediaQueries.small.maxWidth &&
            `@media (max-width: ${theme.mediaQueries.small.maxWidth}) {
                padding-right: 3px;
                padding-left: 3px;
                margin: 0 0px;
            }`
        }
    `}
`;

export const Icon = styled.div``;

export const Col = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Text = styled.div<{ greyedOut?: boolean }>`
    text-align: center;
    font-size: 10px;
    color: ${(props) =>
        props.greyedOut
            ? ({ theme }) => theme.colors.progressText
            : ({ theme }) => theme.colors.defaultText};
    font-family: ProximaNovaCondensed;
    margin: 0 -22px 0px;
    white-space: pre-line;
`;
