import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 16px;
`;

export const Text = styled.div`
    padding-top: 8px;
    font-size: 14px;
    text-align: center;
    ${({ theme }) => `
        color: ${theme.colors.headerText};
        ${
            theme.mediaQueries.extraSmall.maxWidth &&
            `@media (max-width: ${theme.mediaQueries.extraSmall.maxWidth}) {
                font-size: 13px;
            }`
        }
    `}
`;

export const Line = styled.div`
    white-space: nowrap;
`;
