import { ReactSVG } from 'react-svg';
import styled, { useTheme } from 'styled-components';
import { useCopy } from '../../contexts/CopyContext';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px;
`;

const Text = styled.div`
    font-size: 18px;
    color: ${({ theme }) => theme.colors.headerText};
    font-weight: Bold;
    padding-top: 20px;
`;

const Message = styled.div`
    ${({ theme }) => `
        color: ${theme.colors.defaultText};
        font-size: ${theme.defaultText.text.size};
        font-weight: ${theme.defaultText.text.weight};
    `}
    text-align: center;
    padding-top: 20px;
`;

export const NoReferrals = () => {
    const theme = useTheme();
    const copy = useCopy();

    return (
        <Container>
            <ReactSVG
                data-testid={theme.icons.noReferral}
                src={theme.icons.noReferral}
            />
            <Text>No referrals yet</Text>
            <Message>
                Any friends who join FanDuel through your invite link will
                appear here. Any referral a year or older will not be shown.
                {copy.noReferralMessage && ` (${copy.noReferralMessage})`}
            </Message>
            <Message>Start referring friends to earn $$$!</Message>
        </Container>
    );
};
