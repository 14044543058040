import { useTheme } from 'styled-components';
import { Referral } from '../../types/ReferralModel';
import { ReactSVG } from 'react-svg';
import { Bar, Col, Icon, LinePadding, Text } from './ProgressBarStyle';

import circle from '../../assets/icons/common/Circle.svg';
import boltIcon from '../../assets/icons/common/Bolt.svg';
import {
    Line,
    SolidDollarCircle,
    TickCircle,
} from '../../assets/componentIcons/';
import { RewardType } from '../../types/OfferModel';

export type Props = {
    referral: Referral;
    hybrid: boolean;
};

export const ProgressBar = ({
    referral: { progress, productType, rewardType },
    hybrid,
}: Props): JSX.Element => {
    const theme = useTheme();

    if (progress === 'INELIGIBLE') {
        return <div style={{ height: '50px' }} />;
    }

    const isWagered = ['WAGERED', 'BONUS', 'BONUS_FRIEND'].includes(progress);
    const isBonusReceived = ['BONUS', 'BONUS_FRIEND'].includes(progress);
    const expired = progress === 'EXPIRED';
    const tickColor = expired
        ? theme.colors.neutralLine
        : theme.colors.successIcon;

    const renderBonusIcon = (rewardType: RewardType): React.ReactNode => {
        if (rewardType === 'PRICE_BOOST_REWARD')
            return <ReactSVG src={boltIcon} />;
        else return <SolidDollarCircle color={theme.colors.successIcon} />;
    };

    return (
        <Bar>
            <Col>
                <Icon data-testid="left">
                    {progress === 'NOT_JOINED' ? (
                        <ReactSVG id="Circle" src={circle} />
                    ) : (
                        <TickCircle color={tickColor} />
                    )}
                </Icon>
                <Text greyedOut={expired}>
                    {hybrid ? `JOIN\n${productType}` : 'JOIN'}
                </Text>
            </Col>

            <LinePadding>
                <Line
                    stroke={
                        isWagered
                            ? theme.colors.successIcon
                            : theme.colors.neutralLine
                    }
                    visibility={expired ? 'hidden' : 'visibile'}
                />
            </LinePadding>
            <Col>
                <Icon data-testid="middle">
                    {isWagered ? (
                        <TickCircle color={theme.colors.successIcon} />
                    ) : (
                        <ReactSVG id="Circle" src={circle} />
                    )}
                </Icon>
                <Text greyedOut={expired}>
                    {productType === 'SPORTSBOOK'
                        ? 'WAGER\nSETTLED'
                        : 'PLAYED\nTHROUGH'}
                </Text>
            </Col>
            <LinePadding>
                <Line
                    stroke={
                        isBonusReceived
                            ? theme.colors.successIcon
                            : theme.colors.neutralLine
                    }
                    visibility={expired ? 'hidden' : 'visibile'}
                />
            </LinePadding>
            <Col>
                <Icon data-testid="right">
                    {progress === 'BONUS' ? (
                        renderBonusIcon(rewardType)
                    ) : progress === 'BONUS_FRIEND' ? (
                        <TickCircle color={theme.colors.successIcon} />
                    ) : (
                        <ReactSVG id="Circle" src={circle} />
                    )}
                </Icon>
                <Text greyedOut={expired}>{`BONUS\n${productType}`}</Text>
            </Col>
        </Bar>
    );
};
