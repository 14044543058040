import React from 'react';
import { Link } from '../Link';
import { ReactSVG } from 'react-svg';

import rg_21 from '../../assets/icons/common/RG_21.svg';
import { Space } from './Footer';
import { Container, Text, Line } from './ResponsibleGamingStyles';

export const ResponsibleGamingSportsbook = () => (
    <Container>
        <ReactSVG src={rg_21} />
        <Text>
            <Line>Gambling Problem? Call 1-800-GAMBLER or</Line>
            <Line>
                visit
                <Space>
                    <Link
                        href="https://www.fanduel.com/rg"
                        target="_blank"
                        children={<>FanDuel.com/RG</>}
                        fontSize={14}
                    />
                </Space>
                (CO, IA, KY, MI, NC, NJ, PA, IL, VA, VT);
            </Line>
            <Line>1-800-NEXT-STEP or text NEXTSTEP to 53342 (AZ),</Line>
            <Line>
                1-888-789-7777 or visit
                <Space>
                    <Link
                        href="https://ccpg.org/chat/"
                        target="_blank"
                        children={<>ccpg.org/chat</>}
                        fontSize={14}
                    />
                </Space>
                (CT),
            </Line>
            <Line>1-800-9-WITH-IT (IN), 1-877-770-STOP (LA),</Line>
            <Line>1-877-8-HOPENY or text HOPENY (467369) (NY),</Line>
            <Line>TN REDLINE 1-800-889-9789 (TN),</Line>
            <Line>1-800-522-4700 (WY),</Line>
            <Line>
                or visit
                <Space>
                    <Link
                        href="https://www.1800gambler.net/"
                        target="_blank"
                        children={<>www.1800gambler.net</>}
                        fontSize={14}
                    />
                </Space>
                (WV).
            </Line>
        </Text>
    </Container>
);
