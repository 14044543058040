export enum RouteHandlerTypes {
    RAF_HOME = 'raf_home',
    LOGIN = 'login',
    LANDING_PAGE = 'landing_page',
}

export interface RouteHandler {
    label: string;
    href: string;
}

export type RouteConfig = {
    routes: Record<RouteHandlerTypes, RouteHandler>;
};
