export enum EventType {
    LinkClick = 'LINK_CLICK', // TODO more events to be created
}
interface ClickEvent {
    label: string;
    href?: string;
}

export interface LinkClickEvent extends ClickEvent {
    type: EventType.LinkClick;
}

export type Event = LinkClickEvent;
