import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ReferralDetails } from '../../lib/types/ReferralModel';
import { RootState } from '../redux/store';
import { HeaderState } from '../types/headerType';
import { OfferDetails } from '../../lib/types/OfferModel';
import { getBackendProduct } from '../utils/appService';

export const rafApi = createApi({
    reducerPath: 'rafApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.API_ENDPOINT,
        prepareHeaders: (headers, { getState }) => {
            const header: HeaderState = (getState() as RootState).header;

            headers.set('authorization', `Bearer ${header.token}`);
            headers.set('ip', `${header.ip}`);
            headers.set('deviceid', `${header.deviceId}`);
            headers.set('username', `${header.username}`);
            headers.set('state', `US-${header.state}`);
            headers.set('product', getBackendProduct(PRODUCT));

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getReferralDetails: builder.query<ReferralDetails, string>({
            query: (referrerId) => `referraldetails/${referrerId}`,
        }),
        getOfferDetails: builder.query<OfferDetails, string>({
            query: (code) => `landingpage/${code}`,
        }),
    }),
});

export const { useGetReferralDetailsQuery, useGetOfferDetailsQuery } = rafApi;
