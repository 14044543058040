import { ReferredUser } from '../../types/ReferralModel';
import { Progress } from '../Progress';
import { ReferralMessage } from './ReferralMessage';

type Props = {
    referredUserList: ReferredUser[];
};

export const ReferralList = (props: Props) => (
    <>
        {props.referredUserList.map((r) => (
            <div key={r.refereeId}>
                <Progress referredUser={r} />
            </div>
        ))}
        <ReferralMessage />
    </>
);
