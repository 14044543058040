type IconProps = {
    color: string;
};

const SolidDollarCircle = (props: IconProps) => (
    <svg
        width="17"
        height="17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        name="SolidDollar"
    >
        <circle opacity=".15" cx="8.5" cy="8.5" fill={props.color} r="8.5" />
        <path
            d="M16.5 8.5a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z"
            fill={props.color}
            stroke={props.color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.381 14c.231 0 .419-.166.419-.37v-.818l.146-.007C11.242 12.722 12 11.991 12 10.8V9.255c0-.795-.527-1.336-1.484-1.555l-2.66-.62v-.724h1.261v.616c0 .204.188.369.419.369h1.943c.23 0 .418-.165.418-.37V6.2c0-1.216-.777-1.956-2.097-2.01v-.822C9.8 3.165 9.612 3 9.38 3h-1.71c-.232 0-.42.165-.42.368v.82c-1.37.045-2.174.784-2.174 2.012v1.31c0 .829.546 1.404 1.299 1.586l2.831.66v.887H7.78v-.549c0-.203-.188-.369-.419-.369H5.418c-.23 0-.418.166-.418.37v.704c0 1.263.838 2.012 2.252 2.014v.818c0 .203.187.368.418.368h1.711Zm0-1.925c-.23 0-.418.166-.418.37v.817h-.875v-.818c0-.203-.187-.369-.418-.369h-.412c-.953 0-1.421-.417-1.421-1.276v-.336h1.106v.27c0 .394.291.648.74.648h1.621c.449 0 .74-.254.74-.649V9.68c0-.341-.222-.534-.61-.634l-2.84-.662c-.4-.097-.68-.392-.68-.875v-1.31c0-.858.48-1.276 1.473-1.276h.283c.231 0 .418-.165.418-.369v-.817h.875v.817c0 .204.187.37.418.37h.258c.953 0 1.421.417 1.421 1.276v.403H9.954v-.336c0-.395-.291-.649-.74-.649H7.76c-.448 0-.74.254-.74.649v.85c0 .35.194.568.585.668l2.699.629c.606.138.86.398.86.84V10.8c0 .859-.481 1.277-1.474 1.277h-.309Z"
            fill="#fff"
        />
    </svg>
);

export default SolidDollarCircle;
