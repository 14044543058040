import { ReactElement } from 'react';
import * as amplitude from '@fanduel/core-analytics';
import styled from 'styled-components';
import { tagIt } from '../../utils/gtmService';

export interface LinkProps {
    children: ReactElement;
    href: string;
    target: string;
    fontSize: number;
}

const StyledLink = styled.a`
    font-size: ${(props: { fontSize: number }) => props.fontSize}px;
    text-decoration: none;
    ${({ theme }) => `
        color: ${theme.colors.linkText};
        font-weight: ${theme.link.text.weight};
    `}
`;

export const Link = (props: LinkProps) => {
    return (
        <StyledLink
            href={props.href}
            target={props.target}
            fontSize={props.fontSize}
            onClick={(event) => {
                amplitude.log('RAF Terms Link Clicked', {
                    Module: 'raf_hub',
                    'Link URL': props.href,
                    'Link Text': event.currentTarget.innerText,
                });
                tagIt('navigation_link_clicked', props.href, 'refer_a_friend');
            }}
            data-testid="tnc-url"
        >
            {props.children}
        </StyledLink>
    );
};
