import { ReactSVG } from 'react-svg';
import {
    StepContainer,
    IconWrapper,
    StepText,
    StepLabel,
    StepDescription,
} from './HowItWorksStyles';
import { StepProps } from './HowItWorks';

export const Step = (props: StepProps) => {
    const { label, description, icon, color } = props;

    return (
        <StepContainer>
            <IconWrapper>
                <ReactSVG src={icon} />
            </IconWrapper>
            <StepText>
                <StepLabel color={color}>{label}</StepLabel>
                <StepDescription>{description}</StepDescription>
            </StepText>
        </StepContainer>
    );
};
