import TagManager from 'react-gtm-module';

export const tagIt = (
    gaEventAction: string,
    gaEventLabel: string | number,
    module: string
) => {
    const gtmArgs = {
        dataLayer: {
            event: 'refer_a_friend',
            gaEventCategory: 'refer_a_friend',
            gaEventAction: gaEventAction,
            gaEventLabel: gaEventLabel,
            module: module,
        },
    };
    TagManager.dataLayer(gtmArgs);
};
