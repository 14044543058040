type IconProps = {
    color?: string;
};

const Up = ({ color = '#1381E0' }: IconProps) => (
    <svg
        width="9"
        height="6"
        viewBox="0 0 9 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M.147 5.353a.5.5 0 0 0 .707 0L4.5 1.707l3.646 3.646a.5.5 0 0 0 .706-.707l-4-4a.498.498 0 0 0-.708 0l-4 4a.502.502 0 0 0 .003.707Z"
            fill={color}
        />
    </svg>
);

export default Up;
