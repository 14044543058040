import React, { ReactElement } from 'react';
import { AppName } from '../../lib/types';
import { getTheme } from '../../lib/utils/themeService';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from '../../lib/themes/GlobalStyle';
import { AppContextProvider } from '../../lib/contexts/AppContext';
import { getProductName } from '../utils/appService';

const appName: AppName = getProductName(PRODUCT) as AppName;

// TODO - dark theme
const darkThemeMap: Map<AppName, AppName> = new Map();
darkThemeMap.set(AppName.SPORTSBOOK, AppName.SPORTSBOOK);
darkThemeMap.set(AppName.CASINO, AppName.CASINO);

interface ThemeProps {
    appName: AppName;
    children: ReactElement;
}

const WithTheme: React.FC<ThemeProps> = ({ appName, children }) => {
    return (
        <ThemeProvider theme={getTheme(appName)}>
            <GlobalStyle />
            {children}
        </ThemeProvider>
    );
};

type ContextProps = {
    node: React.ReactElement;
    theme: string | null;
    appName?: string | null;
};

export const ContextWrapper = (props: ContextProps): JSX.Element => {
    let app: AppName = appName;
    if (props.theme && props.theme == 'dark') {
        app = darkThemeMap.get(appName) as AppName;
    }

    if (props.appName === 'casino' && !NODE_ENV.startsWith('prod')) {
        app = AppName.CASINO;
    }

    return (
        <AppContextProvider appName={app}>
            <WithTheme appName={app}>{props.node}</WithTheme>
        </AppContextProvider>
    );
};
