import React from 'react';
import { Link } from '../Link';
import { ReactSVG } from 'react-svg';

import rg_21 from '../../assets/icons/common/RG_21.svg';
import { Container, Text, Line } from './ResponsibleGamingStyles';

export const Space = ({ children }: { children: React.ReactElement }) => (
    <> {children} </>
);

export const ResponsibleGamingCasino = () => (
    <Container>
        <ReactSVG src={rg_21} />
        <Text>
            <Line>Gambling Problem? Call 1-800-GAMBLER or</Line>
            <Line>
                visit
                <Space>
                    <Link
                        href="https://www.fanduel.com/rg"
                        target="_blank"
                        children={<>FanDuel.com/RG</>}
                        fontSize={14}
                    />
                </Space>
                (MI, NJ, PA);
            </Line>
            <Line>
                or visit
                <Space>
                    <Link
                        href="https://www.1800gambler.net/"
                        target="_blank"
                        children={<>www.1800gambler.net</>}
                        fontSize={14}
                    />
                </Space>
                (WV).
            </Line>
        </Text>
    </Container>
);
