import { useTheme } from 'styled-components';
import { Header } from '../../components';
import { ErrorPage } from './ErrorPage';

interface LandingErrorPageProps {
    header?: string;
    message?: string;
}

export const LandingErrorPage = ({
    header,
    message,
}: LandingErrorPageProps) => {
    const theme = useTheme();
    return (
        <>
            <Header />
            <ErrorPage
                headerText={
                    header
                        ? header
                        : theme.text.errorPage.landingPage.headerText
                }
                message={
                    message ? message : theme.text.errorPage.landingPage.message
                }
                buttonText={theme.text.errorPage.landingPage.buttonText}
                buttonHref={process.env.HOME_URL}
                buttonTarget={'_parent'}
            />
        </>
    );
};
