import { ReactNode, useState } from 'react';
import { useTheme } from 'styled-components';
import * as amplitude from '@fanduel/core-analytics';

import { Offer } from 'Types';
import { tagIt } from 'LibUtils';
import { useCopy } from 'Contexts';
import { LandingProps } from 'Views';

import { Link } from '../Link';
import { Steps } from './Steps';
import {
    Button,
    Container,
    Terms,
    Text,
    TextSection,
} from './LandingHowItWorksStyles';
import { StateSelector } from '../StateSelector/StateSelector';

export type LandingHowItWorksProps = Pick<
    Offer,
    'casinoRefereeRewardAmount' | 'offerType' | 'tncUrl' | 'rewardType'
> &
    Pick<LandingProps, 'joinUrl'>;

export const LandingHowItWorks = ({
    casinoRefereeRewardAmount,
    offerType,
    rewardType,
    joinUrl,
    tncUrl,
}: LandingHowItWorksProps) => {
    const theme = useTheme();
    const copy = useCopy();

    const isCasino = theme.name === 'casino';
    const isZeroRecruit = casinoRefereeRewardAmount === 0;

    const [buttonUrl, setButtonUrl] = useState(isCasino ? '' : joinUrl);

    const onStateSelected = (stateAbbreviation: string) => {
        setButtonUrl(
            joinUrl.replace('%state%', stateAbbreviation.toLowerCase())
        );
    };

    const getHostname = () => {
        try {
            return new URL(tncUrl).hostname.replace('www.', '');
        } catch {
            return 'fanduel';
        }
    };

    const buttonText = copy.joinButton;

    const termsText = (
        <>
            {copy.landingConditionsText}
            <Link
                children={<>{getHostname()}</>}
                href={tncUrl}
                target="_blank"
                fontSize={10}
            />
            .
        </>
    );

    const handleButtonClick = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        if (!buttonUrl) {
            event.preventDefault();
            return false;
        }

        tagIt('cta_clicked', buttonText, 'refer_a_friend');
        amplitude.log('RAF Recruit CTA Clicked', {
            Module: 'raf_recruit',
            'Link URL': buttonUrl,
            'Link Text': buttonText,
        });
    };

    const paragraphs = copy.landingHowitWorks.split('\n');
    const text: ReactNode[] = paragraphs.map(
        (paragraph: string, index: number) => {
            return (
                <Text key={index} data-testid={index}>
                    {paragraph}
                </Text>
            );
        }
    );

    return (
        <Container
            isZeroRecruit={isCasino && isZeroRecruit}
            data-testid="how-it-works"
        >
            {copy.landingHowitWorks && <TextSection>{text}</TextSection>}
            {isCasino && isZeroRecruit ? (
                <></>
            ) : (
                <Steps {...{ offerType, rewardType }} />
            )}
            {isCasino && <StateSelector onChange={onStateSelected} />}
            <Button
                onClick={handleButtonClick}
                href={buttonUrl}
                disabled={!buttonUrl}
                data-testid="join-button"
            >
                {buttonText}
            </Button>
            <Terms>{termsText}</Terms>
        </Container>
    );
};
