export const sportsbookText = {
    maxHeader: "You're a scouting pro!",
    errorPage: {
        referralCenter: {
            headerText: "Aaaaand it's no good!",
            message:
                'Something went wrong on our end. Please check back later.',
            buttonText: 'Back home',
        },
        landingPage: {
            headerText: 'Something went wrong',
            message: 'We’re looking into it. Try again later',
            buttonText: 'Visit FanDuel Sportsbook',
        },
    },
};
