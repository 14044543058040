import {
    Offer,
    OfferRenderType,
    OriginatingProduct,
    RewardSplit,
} from '../types/OfferModel';
import { determineRewardSplitType } from '../../app/utils/commonFunctions';
import { copy, defaultCopy, canadaCopy, DefaultCopy } from './copy';

const getCopy = (
    product: OriginatingProduct,
    offerType: OfferRenderType,
    rewardSplit: RewardSplit,
    showCanadaCopy: boolean
) => {
    const overrideCopy = copy[product][offerType][rewardSplit];
    const canadaOverride = showCanadaCopy
        ? canadaCopy[product][offerType][rewardSplit]
        : undefined;

    const finalCopy: DefaultCopy = {
        ...defaultCopy,
        ...overrideCopy,
        ...canadaOverride,
    };
    return finalCopy;
};

export const generateCopy = (
    {
        casinoRefereeRewardAmount,
        casinoReferrerRewardAmount,
        casinoWagerAmount,
        offerType,
        offerWindowDays,
        product,
        rewardType,
        sbkRefereeRewardAmount,
        sbkReferrerRewardAmount,
        sbkWagerAmount,
        percentage,
    }: Offer,
    referrerUsername?: string,
    showCanadaCopy = false
) => {
    const rewardSplit = determineRewardSplitType(
        sbkReferrerRewardAmount,
        sbkRefereeRewardAmount,
        casinoReferrerRewardAmount,
        casinoRefereeRewardAmount,
        rewardType
    );

    const offerRenderType: OfferRenderType =
        offerType == 'HYBRID' ? 'hybrid' : 'standAlone';

    const rawCopy = getCopy(
        product,
        offerRenderType,
        rewardSplit,
        showCanadaCopy
    );

    const replaceWith: {
        [id: string]: any;
    } = {
        '%sbkWagerAmount%': sbkWagerAmount,
        '%sbkReferrerRewardAmount%': sbkReferrerRewardAmount,
        '%sbkRefereeRewardAmount%': sbkRefereeRewardAmount,
        '%casinoWagerAmount%': casinoWagerAmount,
        '%casinoReferrerRewardAmount%': casinoReferrerRewardAmount,
        '%casinoRefereeRewardAmount%': casinoRefereeRewardAmount,
        '%offerWindowDays%': offerWindowDays || 30,
        '%referrerRewardAmount%':
            sbkReferrerRewardAmount + casinoReferrerRewardAmount,
        '%refereeRewardAmount%':
            sbkRefereeRewardAmount + casinoRefereeRewardAmount,
        '%referrerUsername%': referrerUsername,
        '%percentage%': percentage,
    };

    const processedCopy = JSON.parse(
        JSON.stringify(rawCopy).replace(
            /%sbkWagerAmount%|%sbkReferrerRewardAmount%|%sbkRefereeRewardAmount%|%casinoWagerAmount%|%casinoReferrerRewardAmount%|%casinoRefereeRewardAmount%|%offerWindowDays%|%referrerRewardAmount%|%refereeRewardAmount%|%referrerUsername%|%percentage%/gi,
            (match) => replaceWith[match]?.toString()
        )
    );

    return processedCopy;
};
