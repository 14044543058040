import styled from 'styled-components';
import { useCopy } from '../../contexts/CopyContext';

const StyledText = styled.span`
    ${({ theme }) => `
        color: ${theme.colors.topSectionText};
        font-size: ${theme.welcome.text.size};
        ${
            theme.mediaQueries.extraSmall.maxWidth &&
            `@media (max-width: ${theme.mediaQueries.extraSmall.maxWidth}) {
                font-size: ${theme.welcome.text.extraSmallSize};
            }`
        }
    `}
    white-space: pre-wrap;
`;

const BoldStyledText = styled(StyledText)`
    font-weight: bold;
    ${({ theme }) => `
        color: ${theme.colors.topSectionText};
    `}
    white-space: pre-wrap;
`;

export const WelcomeText = () => {
    const copy = useCopy();
    return (
        <>
            <StyledText>{copy.welcomeText}</StyledText>
            <BoldStyledText>{copy.welcomeTextReward}</BoldStyledText>
        </>
    );
};
