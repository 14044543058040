import { datadogLogs } from '@datadog/browser-logs';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import root from 'window-or-global';
import * as amplitude from '@fanduel/core-analytics';
import { Loading } from '../../lib/components';
import { ReferrerHomePage } from '../../lib/views';
import { tagScreenLoad } from '../api/gtmAPI';
import { useGetReferralDetailsQuery } from '../api/rafAPI';
import { ContextWrapper } from '../contexts/AppContext';
import { setError, setHeader, setLoading } from '../redux/headerSlice';
import { RootState } from '../redux/store';
import { GtmProps } from '../types/gtmTypes';
import {
    HeaderState,
    PageProps,
    ReferrerProps,
    UserDetails,
} from '../types/headerType';
import { parseToken } from '../utils/headerService';
import { ReferralErrorPageContainer } from './ErrorPage';
import { isAccountService } from '../utils/appService';
import { getGtmSiteVersion } from '../utils/siteVersionPlatform';
import { getCookieValues } from '../utils/getCookieValues';

const errorPage = (param: PageProps): JSX.Element => {
    return (
        <ReferralErrorPageContainer
            theme={param.theme}
            gtmProps={param.gtmProps}
        />
    );
};

const ReferrerHomeContainer = () => {
    const theme = new URLSearchParams(root.location.search).get('theme');

    const deviceId = new URLSearchParams(root.location.search).get('deviceId');

    const dispatch = useDispatch();
    useEffect(() => {
        const { region, token } = getCookieValues();

        if (token) {
            amplitude.setUserId(parseToken(token).sub);
        }

        if (token && region) {
            dispatch(
                setHeader({
                    token: token,
                    state: region,
                    ip: '',
                    deviceId,
                    username: parseToken(token).usn,
                    loading: false,
                    error: false,
                })
            );
            dispatch(setLoading(false));
        } else {
            // This is a temporary fix for missing userinfo issue
            const referrerUrl = document.referrer;
            datadogLogs.logger.info('referrerUrl:' + referrerUrl);
            if (referrerUrl && isAccountService(referrerUrl)) {
                datadogLogs.logger.info(
                    'Redirecting user to:' + process.env.HOME_URL
                );
                // This logic is meant to be executed only when user comes from account page
                window.location.replace(process.env.HOME_URL);
                return;
            } else {
                datadogLogs.logger.error(
                    'Referrer Home Page missing user info - no call to back end',
                    {
                        errorDetails: {
                            state: region,
                            ipAddress: '',
                            deviceId, 
                            username: token
                                ? parseToken(token).usn : 'missing',
                            userID: token
                                ? parseToken(token).sub
                                : 'auth token missing',
                        },
                    }
                );
            }
            dispatch(setLoading(false));
            dispatch(setError(true));
        }

        amplitude.log('RAF Hub Page Viewed');
    }, []);

    const header: HeaderState = useSelector((state: RootState) => state.header);

    if (header.loading) {
        return <ContextWrapper theme={theme} node={<Loading />} />;
    }

    if (header.error) {
        return errorPage({
            theme: theme,
            gtmProps: {
                jurisdiction: header.state,
                path: '/refer-error',
                fdAccountId: undefined,
                siteVersion: getGtmSiteVersion,
                loginStatus: 'logged_out',
            },
        });
    }

    return (
        <ReferrerBox
            pageProps={{
                theme: theme,
                gtmProps: {
                    jurisdiction: header.state,
                    path: '/refer',
                    fdAccountId: undefined,
                    siteVersion: getGtmSiteVersion,
                    loginStatus: 'logged_out',
                },
            }}
            header={header}
        />
    );
};

const ReferrerBox = ({ header, pageProps }: ReferrerProps): JSX.Element => {
    const user: UserDetails = parseToken(header?.token);
    const referrerId = user.sub;

    const {
        data: referralDetailsData,
        error: referralDetailsError,
        isLoading: referralDetailsLoading,
    } = useGetReferralDetailsQuery(referrerId);

    if (referralDetailsLoading) {
        return <ContextWrapper theme={pageProps.theme} node={<Loading />} />;
    }

    if (referralDetailsData) {
        datadogLogs.logger.info('GET request successful - Referrer Home Page', {
            referrerId,
            referralDetailsData,
        });

        const referralGtmProps: GtmProps = {
            ...pageProps.gtmProps,
            fdAccountId: referrerId,
        };

        tagScreenLoad(referralGtmProps);
        const homePage = (
            <ReferrerHomePage
                referredUserData={referralDetailsData.referralsForUserDTOList}
                offerData={referralDetailsData.referralOfferDTO}
                link={referralDetailsData.link}
                isMaxReferralsReached={
                    referralDetailsData.isMaxReferralsReached
                }
            />
        );
        return <ContextWrapper theme={pageProps.theme} node={homePage} />;
    }

    if (referralDetailsError) {
        datadogLogs.logger.error('GET request failed - Referrer Home Page', {
            referrerId,
            referralDetailsError,
        });
    }

    return errorPage({
        theme: pageProps.theme,
        gtmProps: {
            ...pageProps.gtmProps,
            path: '/refer-error',
            fdAccountId: referrerId,
        },
    });
};

export default ReferrerHomeContainer;
