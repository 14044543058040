// Reference - https://fanduel.design/formation/guidelines/breakpoints/
enum MEDIA_QUERY {
    EXTRA_LARGE = 'extraLarge',
    LARGE = 'large',
    MEDIUM = 'medium',
    SMALL = 'small',
    EXTRA_SMALL = 'extraSmall',
}

interface BreakpointType {
    minWidth?: string;
    maxWidth?: string;
}

interface MediaQueries {
    [MEDIA_QUERY.EXTRA_LARGE]: BreakpointType;
    [MEDIA_QUERY.LARGE]: BreakpointType;
    [MEDIA_QUERY.MEDIUM]: BreakpointType;
    [MEDIA_QUERY.SMALL]: BreakpointType;
    [MEDIA_QUERY.EXTRA_SMALL]: BreakpointType;
}

type WeightStyleType =
    | 'normal'
    | 'bold'
    | 'bolder'
    | 'lighter'
    | 'number'
    | 'initial'
    | 'inherit';

interface TextStyleType {
    size: string;
    weight: WeightStyleType;
    extraSmallSize: string;
    boldText: WeightStyleType;
    textDecoration: string;
    whiteSpaceSmall: string;
    whiteSpaceLarge: string;
}

interface TextStyle {
    text: TextStyleType;
}
interface ErrorPageDetails {
    headerText: string;
    message: string;
    buttonText: string;
}

interface Text {
    maxHeader: string;
    errorPage: {
        referralCenter: ErrorPageDetails;
        landingPage: ErrorPageDetails;
    };
}

interface Colors {
    pageBg: string;
    contentBg: string;
    containerBg: string;
    headerBg: string;
    containerBorder: string;
    inviteBorder: string;
    headerText: string;
    defaultText: string;
    progressText: string;
    buttonText: string;
    linkText: string;
    buttonGradient: string;
    successTextDark: string;
    successTextLight: string;
    warningText: string;
    successIcon: string;
    successIconDark: string;
    successIconLight: string;
    neutralLine: string;
    topSectionText: string;
    topSectionThemeBg: string;
    termsAndConditionsText: string;
    headerLogoBg: string;
    joinFanduelBtn: string;
    stateSelectorDetail: string;
    stateSelectorArrow: string;
    disabledButton: string;
    disabledButtonText: string;
}

interface Icons {
    main: string;
    noReferral: string;
    error: string;
    leftDots: string;
    logo: string;
    rightDots: string;
    landingGraphic: string;
}

interface Sections {
    topSection: TopSection;
    headerSection: string;
    landingPage: number;
}

interface TopSection {
    row: MarginSide;
    rowSmall: MarginSide;
    outer: MarginSide;
    column: MarginSide;
    imageDiv: DivMargins;
    styledSubTitle: MarginSide;
    topMarginSpacing: TopMarginSpacing;
}

interface TopMarginSpacing {
    extraSmall: string;
    small: string;
    medium: string;
    large: string;
    extraLarge: string;
    maxSpacing: string;
}

interface DivMargins {
    medium: MarginSide;
    small: MarginSide;
    extraSmall: MarginSide;
    maxWidth: string;
    maxHeight: string;
}

interface MarginSide {
    margin: string;
    medium: string;
}

interface Scale {
    medium: string;
    small: string;
    extraSmall: string;
}

interface PaddingValue {
    large: string;
    small: string;
}

interface Padding {
    column: PaddingValue;
    horizontalPadding: PaddingValue;
    errorPagePadding: PaddingValue;
}

interface BorderRadius {
    horizontalPadding: BorderRadiusSide;
}

interface BorderRadiusSide {
    radius: string;
}

interface ZIndexItem {
    index: string;
}

interface Overflow {
    imageDiv: OverflowType;
    home: OverflowType;
}

interface OverflowType {
    isHidden: string;
}

interface Dimensions {
    width: string;
    subtitleMaxWidth: string;
    errorPageHeight: string;
}

export interface ThemeType {
    name: string;
    fontFamily: string;
    colors: Colors;
    mediaQueries: MediaQueries;
    icons: Icons;
    text: Text;
    header: TextStyle;
    subTitle: TextStyle;
    welcome: TextStyle;
    link: TextStyle;
    defaultText: TextStyle;
    borderRadius: BorderRadius;
    margins: Sections;
    paddings: Padding;
    overflow: Overflow;
    scale: Scale;
    dimensions: Dimensions;
    position: string;
    positionType: string;
    positionTop: string;
}
