import { isBrowser } from 'react-device-detect';
import styled from 'styled-components';
import { fanduelGreyD3 } from '../../themes/colors';

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    ${({ theme }) => `
        background-color: ${theme.colors.contentBg};
        border: solid 1px ${theme.colors.containerBorder};
        ${
            theme.mediaQueries.medium.maxWidth &&
            `@media (max-width: ${theme.mediaQueries.medium.maxWidth}) {
                border-radius: ${isBrowser ? '4px' : '0px'};
                border-left: solid ${isBrowser ? '1px' : '0px'} ${
                theme.colors.containerBorder
            };
                border-right: solid ${isBrowser ? '1px' : '0px'} ${
                theme.colors.containerBorder
            };
                margin: ${isBrowser ? '16px' : '0px'};
                padding: 0px;
                max-width: none;
            }`
        }
    `}
`;

export const CardHeader = styled.div`
    display: flex;
    flex: 1;
    height: 40px;
    align-items: center;
    margin-bottom: 8px;
    ${({ theme }) => `
        color: ${theme.colors.headerText};
        border-bottom: solid 1px ${theme.colors.containerBorder};
    `}
`;

export const CardTitle = styled.div`
    font-weight: 700;
    font-size: 16px;
    padding: 10px 16px;
`;

export const StepContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 16px;
`;

export const IconWrapper = styled.div`
    svg {
        width: 38px;
        height: 38px;
        .dollar-fill {
            fill: ${({ theme }) => theme.colors.successIconLight};
        }
        .dollar-stroke {
            stroke: ${({ theme }) => theme.colors.successIconLight};
        }
        .icon-fill {
            fill: ${fanduelGreyD3};
        }
        .icon-stroke {
            stroke: ${fanduelGreyD3};
        }
    }

    align-self: center;
    width: 38px;
    height: 38px;
`;

export const StepText = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    color: ${({ theme }) => theme.colors.defaultText};
`;

export const StepLabel = styled.div`
    font-size: 14px;
    font-weight: 700;
    color: ${(props) => props.color || props.theme.colors.defaultText};
`;

export const StepDescription = styled.div`
    font-size: 12px;
`;

export const TnCContainer = styled.div`
    ${({ theme }) => `
        background: ${theme.colors.containerBg};
        border-top solid 1px ${theme.colors.containerBorder};
    `}
    padding: 12px 16px;
    margin-top: 8px;
    border-radius: 0px 0px 4px 4px;
`;
