import { ReactSVG } from 'react-svg';
import { useTheme } from 'styled-components';

import { Country } from 'AppUtils';
import { OfferDetails } from 'Types';
import { CopyContext } from 'Contexts';
import { LandingHowItWorks, Header, Footer, LandingWelcome } from 'Components';

import {
    Container,
    Graphic,
    LandingStyle,
    SvgWrapper,
    TopMargin,
} from './LandingPageStyles';
import { generateCopy } from '../../copy';

export type LandingProps = OfferDetails & { joinUrl: string; country: Country };

export const LandingPage = ({
    referralOfferDTO,
    referrerUsername,
    joinUrl,
    country,
}: LandingProps) => {
    const theme = useTheme();

    const copy = generateCopy(
        referralOfferDTO,
        referrerUsername,
        country === 'canada'
    );
    return (
        <CopyContext.Provider value={copy}>
            <LandingStyle>
                <Header />
                <TopMargin value={theme.margins.landingPage} />
                <Container data-testid="main-container">
                    <Graphic>
                        <ReactSVG src={theme.icons.leftDots} />
                        <SvgWrapper>
                            <ReactSVG src={theme.icons.landingGraphic} />
                        </SvgWrapper>
                        <ReactSVG src={theme.icons.rightDots} />
                    </Graphic>
                    <TopMargin value={16}>
                        <LandingWelcome />
                    </TopMargin>
                    <LandingHowItWorks
                        casinoRefereeRewardAmount={
                            referralOfferDTO.casinoRefereeRewardAmount
                        }
                        offerType={referralOfferDTO.offerType}
                        rewardType={referralOfferDTO.rewardType}
                        joinUrl={joinUrl}
                        tncUrl={referralOfferDTO.tncUrl}
                    />
                    <Footer
                        product={referralOfferDTO.product}
                        country={country}
                    />
                </Container>
            </LandingStyle>
        </CopyContext.Provider>
    );
};
