import React, { createContext, ReactElement } from 'react';
import { AppName } from '../types';

export interface AppState {
    appName: AppName | null;
    children: ReactElement;
}

interface AppCtxState {
    appName: AppName | null;
}

const appCtxDefaultValue: AppCtxState = {
    appName: null,
};

export const AppStateContext = createContext(appCtxDefaultValue);

export const AppContextProvider: React.FC<AppState> = ({
    appName,
    children,
}) => {
    return (
        <AppStateContext.Provider
            value={{
                appName,
            }}
        >
            {children}
        </AppStateContext.Provider>
    );
};
