import { useTheme } from 'styled-components';
import { Offer } from '../../types/OfferModel';
import { Link } from '../Link';
import { Card, CardHeader, CardTitle, TnCContainer } from './HowItWorksStyles';
import { Step } from './Step';
import {
    casBonusAsymmetricStep,
    casBonusStep,
    casBonusStepZeroRecruit,
    casWagerStep,
    HowItWorksStep,
    joinStep,
    sbkBonusAsymmetricStep,
    sbkProfitBoostToken,
    sbkBonusStep,
    sbkBonusStepZeroRecruit,
    sbkWagerStep,
} from './StepsData';
import { determineRewardSplitType } from '../../../app/utils/commonFunctions';

export type StepProps = {
    label: string;
    description: string;
    icon: string;
    color: string;
};

export type HowItWorksProps = Pick<
    Offer,
    | 'sbkReferrerRewardAmount'
    | 'sbkRefereeRewardAmount'
    | 'sbkWagerAmount'
    | 'casinoReferrerRewardAmount'
    | 'casinoRefereeRewardAmount'
    | 'casinoWagerAmount'
    | 'offerType'
    | 'tncUrl'
    | 'offerWindowDays'
    | 'rewardType'
    | 'percentage'
> & { isAsymmetric: boolean };

export const HowItWorks = ({
    sbkReferrerRewardAmount,
    sbkRefereeRewardAmount,
    sbkWagerAmount,
    casinoReferrerRewardAmount,
    casinoRefereeRewardAmount,
    casinoWagerAmount,
    offerType,
    tncUrl,
    offerWindowDays,
    rewardType,
    percentage,
}: HowItWorksProps) => {
    const hybrid = offerType == 'HYBRID';
    const theme = useTheme();

    const productSpecficSteps = getProductSpecificSteps();

    const arrangeSteps = productSpecficSteps.reduce(
        (array: HowItWorksStep[][], element) => {
            element.product == theme.name.toUpperCase()
                ? array[0].push(element)
                : array[1].push(element);
            return array;
        },
        [[joinStep], []]
    );

    const getSteps = hybrid
        ? ([] as HowItWorksStep[]).concat(arrangeSteps[0], arrangeSteps[1])
        : arrangeSteps[0];

    const replaceWith: {
        [id: string]: number;
    } = {
        '%sbkWagerAmount%': sbkWagerAmount,
        '%sbkReferrerRewardAmount%': sbkReferrerRewardAmount,
        '%sbkRefereeRewardAmount%': sbkRefereeRewardAmount,
        '%casinoWagerAmount%': casinoWagerAmount,
        '%casinoReferrerRewardAmount%': casinoReferrerRewardAmount,
        '%casinoRefereeRewardAmount%': casinoRefereeRewardAmount,
        '%offerWindowDays%': offerWindowDays || 30,
        '%percentage%': percentage,
    };

    const getLabel = (label: string) => {
        return label.replace(
            /%sbkWagerAmount%|%sbkReferrerRewardAmount%|%sbkRefereeRewardAmount%|%casinoWagerAmount%|%casinoReferrerRewardAmount%|%casinoRefereeRewardAmount%|%offerWindowDays%|%percentage%/gi,
            (match) => replaceWith[match]?.toString()
        );
    };

    const getColor = (isBonusColor: boolean) =>
        isBonusColor ? theme.colors.successTextLight : theme.colors.defaultText;

    const convertSteps: StepProps[] = getSteps.map((s: HowItWorksStep) => {
        return {
            label: hybrid
                ? getLabel(s.label + s.labelExtension)
                : getLabel(s.label),
            description: getLabel(s.description),
            icon: s.icon,
            color: getColor(s.isBonusColor),
        };
    });

    const steps: StepProps[] = convertSteps;

    return (
        <Card data-testid="how-it-works">
            <CardHeader>
                <CardTitle>How it works</CardTitle>
            </CardHeader>

            {steps.map((step) => {
                const s = {
                    ...step,
                };
                return <Step key={step.label} {...s} />;
            })}

            <TnCContainer>
                <Link
                    href={tncUrl}
                    children={<>Terms and conditions</>}
                    target="_blank"
                    fontSize={14}
                />
            </TnCContainer>
        </Card>
    );

    function getProductSpecificSteps(): HowItWorksStep[] {
        const rewardSplitType = determineRewardSplitType(
            sbkReferrerRewardAmount,
            sbkRefereeRewardAmount,
            casinoReferrerRewardAmount,
            casinoRefereeRewardAmount,
            rewardType
        );

        const baseSteps: HowItWorksStep[] = [sbkWagerStep, casWagerStep];

        const typeSpecificSteps: Record<string, HowItWorksStep[]> = {
            zeroRecruit: [sbkBonusStepZeroRecruit, casBonusStepZeroRecruit],
            asymmetric: [sbkBonusAsymmetricStep, casBonusAsymmetricStep],
            profitBoost: [sbkProfitBoostToken],
            symmetric: [sbkBonusStep, casBonusStep],
        };

        return [
            ...baseSteps,
            ...(typeSpecificSteps[rewardSplitType] ||
                typeSpecificSteps['symmetric']),
        ];
    }
};
