import styled from 'styled-components';
import { OfferType } from '../../types/OfferModel';

const StyledSubtitle = styled.h1<{
    centered?: boolean;
    text?: string;
    fontSize?: string;
}>`
    justify-content: ${(props) => (props.centered ? 'center' : 'flex-start')};
    max-width: ${(props) =>
        props.text ? ({ theme }) => theme.dimensions.subtitleMaxWidth : ''};
    display: flex;
    font-size: ${(props) =>
        props.fontSize ? `${props.fontSize} !important` : 'unset'};
    ${({ theme }) => `
        font-weight: ${theme.subTitle.text.weight};
        font-size: ${theme.subTitle.text.size};
        color: ${theme.colors.topSectionText};
        white-space: ${theme.subTitle.text.whiteSpaceLarge};
        ${
            theme.mediaQueries.extraSmall.maxWidth &&
            `@media (max-width: ${theme.mediaQueries.extraSmall.maxWidth}) {
                font-size: ${theme.subTitle.text.extraSmallSize};
            }`
        }
        ${
            theme.mediaQueries.small.maxWidth &&
            `@media (max-width: ${theme.mediaQueries.small.maxWidth}) {
                white-space: ${theme.subTitle.text.whiteSpaceSmall};
                margin-right: 10px;
            }`
        }
    `}
`;

const StyledSubtitleWrapper = styled(StyledSubtitle)`
    margin-top: -10px;
    position: ${({ theme }) => theme.positionType};
    top: ${({ theme }) => theme.positionTop};
`;

export type SubtitleProps = {
    text?: string;
    offerType: OfferType;
    centered?: boolean;
    fontSize?: string;
};

export const Subtitle = (props: SubtitleProps) => {
    const { text, offerType, centered = true, fontSize } = props;

    const subtitleText = `Invite friends to join FanDuel${
        offerType === 'SPORTSBOOK' ? ' Sportsbook' : ''
    }`;

    return (
        <>
            {!!text ? (
                <StyledSubtitle
                    centered={centered}
                    text={text}
                    fontSize={fontSize}
                >
                    {text}
                </StyledSubtitle>
            ) : (
                <StyledSubtitleWrapper centered={centered}>
                    {subtitleText}
                </StyledSubtitleWrapper>
            )}
        </>
    );
};
