import { configureStore } from '@reduxjs/toolkit';
import { rafApi } from '../api/rafAPI';
import headerReducer from './headerSlice';

export const store = configureStore({
    reducer: {
        [rafApi.reducerPath]: rafApi.reducer,
        header: headerReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(rafApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
