import { ResizeObserver } from '@juggle/resize-observer';
import { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import useMeasure from 'react-use-measure';
import { useTheme } from 'styled-components';
import { determineAsymmetric } from '../../../app/utils/commonFunctions';
import {
    HeaderText,
    HowItWorks,
    HeaderTextSubtitle,
    Invite,
    MaxReferrals,
    TermsAndConditions,
    WelcomeText,
    YourReferrals,
} from '../../components';
import { Offer } from '../../types/OfferModel';
import { ReferredUser } from '../../types/ReferralModel';
import {
    Column,
    Container,
    Home,
    HorizontalPadding,
    ImageDiv,
    Row,
    TopMargin,
    TopMarginText,
} from './ReferrerHomeStyle';
import { postHeight } from '../../utils/heightPostMessage';
import { CopyContext } from '../../contexts/CopyContext';
import { generateCopy } from '../../copy/generateCopy';

type HomeProps = {
    referredUserData: ReferredUser[];
    offerData: Offer;
    link: string;
    isMaxReferralsReached: boolean;
};

export const ReferrerHomePage = (props: HomeProps) => {
    const theme = useTheme();
    const {
        sbkReferrerRewardAmount,
        sbkRefereeRewardAmount,
        sbkWagerAmount,
        casinoReferrerRewardAmount,
        casinoRefereeRewardAmount,
        casinoWagerAmount,
        offerType,
        tncUrl,
        previewText,
        offerWindowDays,
        rewardType = 'EXTRA_WALLET_REWARD',
        percentage
    } = props.offerData;

    const [componentHeight, setComponentHeight] = useState(0);
    const [ref, { height }] = useMeasure({ polyfill: ResizeObserver });

    const copy = generateCopy(props.offerData);

    useEffect(() => {
        setComponentHeight(height);
    }, [height]);

    useEffect(() => {
        postHeight(height);
    }, [componentHeight]);

    const isAsymmetric = determineAsymmetric(
        sbkReferrerRewardAmount,
        sbkRefereeRewardAmount,
        casinoReferrerRewardAmount,
        casinoRefereeRewardAmount,
        offerType
    );

    return (
        <CopyContext.Provider value={copy}>
            <Home ref={ref}>
                <Container data-testid="main-container">
                    <HeaderText text="Refer a friend" />
                    <HorizontalPadding>
                        <Row data-testid="welcome-panel">
                            <Column>
                                <TopMarginText
                                    value={
                                        theme.margins.topSection
                                            .topMarginSpacing.extraLarge
                                    }
                                >
                                    <WelcomeText />
                                </TopMarginText>
                                <TopMargin
                                    value={
                                        theme.margins.topSection
                                            .topMarginSpacing.extraSmall
                                    }
                                >
                                    <HeaderTextSubtitle
                                        offerType={offerType}
                                        isAsymmetric={isAsymmetric}
                                    />
                                </TopMargin>
                                <TopMargin
                                    value={
                                        theme.margins.topSection
                                            .topMarginSpacing.small
                                    }
                                >
                                    <TermsAndConditions tncUrl={tncUrl} />
                                </TopMargin>
                            </Column>

                            <ImageDiv>
                                <ReactSVG src={theme.icons.main} />
                            </ImageDiv>
                        </Row>
                        <TopMargin
                            value={
                                theme.margins.topSection.topMarginSpacing.medium
                            }
                        >
                            <MaxReferrals
                                isMaxReferralsReached={
                                    props.isMaxReferralsReached
                                }
                                offerType={offerType}
                            />
                        </TopMargin>
                        <TopMargin
                            value={
                                theme.margins.topSection.topMarginSpacing.large
                            }
                        >
                            <Invite
                                sbkRefereeRewardAmount={sbkRefereeRewardAmount}
                                casinoRefereeRewardAmount={
                                    casinoRefereeRewardAmount
                                }
                                percentage={percentage}
                                previewText={previewText}
                                link={props.link}
                            />
                        </TopMargin>
                    </HorizontalPadding>
                    <YourReferrals referredUserList={props.referredUserData} />
                    <TopMargin
                        value={theme.margins.topSection.topMarginSpacing.small}
                    >
                        <HowItWorks
                            sbkReferrerRewardAmount={sbkReferrerRewardAmount}
                            sbkRefereeRewardAmount={sbkRefereeRewardAmount}
                            sbkWagerAmount={sbkWagerAmount}
                            casinoReferrerRewardAmount={
                                casinoReferrerRewardAmount
                            }
                            casinoRefereeRewardAmount={
                                casinoRefereeRewardAmount
                            }
                            casinoWagerAmount={casinoWagerAmount}
                            offerType={offerType}
                            tncUrl={tncUrl}
                            isAsymmetric={isAsymmetric}
                            offerWindowDays={offerWindowDays}
                            rewardType={rewardType}
                            percentage={percentage}
                        />
                    </TopMargin>
                </Container>
            </Home>
        </CopyContext.Provider>
    );
};
