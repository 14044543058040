import styled, { useTheme } from 'styled-components';
import { ReactSVG } from 'react-svg';

const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: ${({ theme }) => theme.paddings.errorPagePadding.large};
    align-items: center;
    flex-direction: column;
    height: ${({ theme }) => theme.dimensions.errorPageHeight};
    ${({ theme }) => `
        ${
            theme.mediaQueries.medium.maxWidth &&
            `@media (max-width: ${theme.mediaQueries.medium.maxWidth}) {
                    justify-content: center;
                    padding: ${theme.paddings.errorPagePadding.small};
            }`
        }
    `}
`;

const Header = styled.div`
    font-size: 18px;
    padding-top: 16px;
    padding-bottom: 4px;
    color: ${({ theme }) => theme.colors.headerText};
`;

const Message = styled.div`
    color: ${({ theme }) => theme.colors.defaultText};
    font-size: ${({ theme }) => theme.defaultText.text.size};
    padding-bottom: 16px;
    width: 256px;
    text-align: center;
`;

const Button = styled.a`
    background: linear-gradient(
        180deg,
        ${({ theme }) => theme.colors.buttonGradient} 0%,
        ${({ theme }) => theme.colors.linkText} 100%
    );
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.buttonText};
    width: fit-content;
    height: 44px;
    align-items: center;
    padding: 13.5px 16px;
    text-decoration: none;
    &:hover {
        cursor: pointer;
    }
`;

export type ErrorProps = {
    headerText: string;
    message: string;
    buttonText: string;
    buttonTarget: string;
    buttonHref: string;
};

export const ErrorPage = ({
    headerText,
    message,
    buttonText,
    buttonTarget,
    buttonHref,
}: ErrorProps) => {
    const theme = useTheme();

    return (
        <>
            <Container>
                <ReactSVG src={theme.icons.error} />
                <Header data-testid="header">{headerText}</Header>
                <Message data-testid="message">{message}</Message>
                <Button href={buttonHref} target={buttonTarget}>
                    {buttonText}
                </Button>
            </Container>
        </>
    );
};
