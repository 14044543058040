export type ProductName = 'sportsbook' | 'casino';
export type BackendProduct = 'SB' | 'CASINO';
export type AmplitudeEnv = 'dev' | 'prod';

export const getProductName = (product: string): ProductName => {
    return product === 'cas' ? 'casino' : 'sportsbook';
};

export const getBackendProduct = (product: string): BackendProduct => {
    return product === 'cas' ? 'CASINO' : 'SB';
};

export const isAccountService = (url: string): boolean => {
    const domain = new URL(url).hostname;
    const validExternalReferrers: RegExp[] = [
        /^account(?:\.[a-z]{2})?\.sportsbook\.qa\.fndl\.dev$/,
        /^account(?:\.[a-z]{2})?\.sportsbook\.fanduel\.com$/,
        /^account(?:\.[a-z]{2})?\.sportsbook\.use1\.dev\.us\.fdbox\.net$/,
        /^account(?:\.[a-z]{2})?\.casino\.qa\.fndl\.dev$/,
        /^account(?:\.[a-z]{2})?\.casino\.fanduel\.com$/,
        /^account(?:\.[a-z]{2})?\.casino\.use1\.dev\.us\.fdbox\.net$/,
    ];
    return validExternalReferrers.some((regex) => regex.test(domain));
};
