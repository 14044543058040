import Cookies from 'js-cookie';

interface CookieValues {
    region?: string;
    token?: string;
    webview: boolean;
}

export function getCookieValues(): CookieValues {
    const region =
        Cookies.get('X-Sportsbook-Region') || Cookies.get('X-Region');

    const token = Cookies.get('X-Auth-Token');

    const webview = Cookies.get('renderMode') === 'Webview';

    return {
        region: region?.toLowerCase(),
        token,
        webview,
    };
}
