import './wdyr';

import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';

import App from './app/App';
import { store } from './app/redux/store';

import * as amplitude from '@fanduel/core-analytics';
import { AmplitudeEnv, getProductName } from './app/utils/appService';
import {
    getAmplitudeSitePlatform,
    getAmplitudeSiteVersion,
} from './app/utils/siteVersionPlatform';
import { getCookieValues } from './app/utils/getCookieValues';
import { getCountry } from './app/utils/getCountry';

const { region } = getCookieValues();
const country = getCountry(NODE_ENV)

if (NODE_ENV !== 'local') {
    amplitude.initWithConfig({
        codeSource: 'raf-app',
        product: getProductName(PRODUCT),
        environment: getAmplitudeEnvironment(NODE_ENV),
        country,
        debug: false,
        requiredEventPropertiesCallback: getRequiredEventPropertiesCallback,
        stateIdCookieHandling: true,
        automaticGlobalProperties: true,
    });

    function getAmplitudeEnvironment(nodeEnv: string): AmplitudeEnv {
        return nodeEnv.startsWith('prod') ? 'prod' : 'dev';
    }

    function getRequiredEventPropertiesCallback():amplitude.RequiredEventProperties {
        return {
            'Page Name': window.location.hash ? 'raf_land' : 'raf_hub',
            'Login Status': window.location.hash ? 'logged_out' : 'logged_in',
            'Jurisdiction': region ?? '',
            'Site Platform': getAmplitudeSitePlatform,
            'Site Version': getAmplitudeSiteVersion,
            Product: 'refer_a_friend',
        }
    }
}

interface AmplitudeWindow extends Window {
    AMPLITUDE_DISABLED_IN_GTM: boolean;
}

declare let window: AmplitudeWindow;

window.AMPLITUDE_DISABLED_IN_GTM = true;

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <HashRouter>
                <App />
            </HashRouter>
        </Provider>
    </React.StrictMode>
);
