export const DUMMY_LINK = 'https://fndl.co/8hh7ra6';

export const PREVIEW_TEXT_SBK =
    'Get $%sbkRefereeRewardAmount% on FanDuel Sportsbook in Bonus Bets! Terms apply. Make sure to use my invite link!';
export const PREVIEW_TEXT_CASINO =
    'Get $%casinoRefereeRewardAmount% on FanDuel Casino! Terms apply. Make sure to use my invite link!';
export const PREVIEW_TEXT_HYBRID =
    'Get $%sbkRefereeRewardAmount% on FanDuel Sportsbook and $%casinoRefereeRewardAmount% on FanDuel Casino. Terms apply. Make sure to use my invite link!';
export const PREVIEW_TEXT_PBT = 'Get (%sbkRefereeRewardAmount%) %percentage%% Tokens on FanDuel Sportsbook! Terms apply. Make sure to use my invite link!'


export const CASINO_STATES = [
    {
        name: 'Michigan',
        abbreviation: 'MI',
    },
    {
        name: 'New Jersey',
        abbreviation: 'NJ',
    },
    {
        name: 'Pennsylvania',
        abbreviation: 'PA',
    },
    {
        name: 'West Virginia',
        abbreviation: 'WV',
    },
];

export const OFFER_EXPIRED_HEADER = 'Offer has expired';

export const OFFER_EXPIRED_MESSAGE =
    'Please contact your referrer for a new referral link.';
