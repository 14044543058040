import { OfferType } from '../../types/OfferModel';
import { Subtitle } from '../Subtitle';
import { MaxReferralsReached } from './MaxReferralsReached';

type MaxReferralsProps = {
    isMaxReferralsReached: boolean;
    offerType: OfferType;
};

export const MaxReferrals = (props: MaxReferralsProps) => {
    return props.isMaxReferralsReached ? (
        <MaxReferralsReached />
    ) : (
        <Subtitle offerType={props.offerType} />
    );
};
