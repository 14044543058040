export const casinoText = {
    maxHeader: "You're on a hot streak!",
    errorPage: {
        referralCenter: {
            headerText: 'Something went wrong',
            message: 'We’re looking into it. Try again later',
            buttonText: 'Visit FanDuel Casino',
        },
        landingPage: {
            headerText: 'Something went wrong',
            message: 'We’re looking into it. Try again later',
            buttonText: 'Visit FanDuel Casino',
        },
    },
};
