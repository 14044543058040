import { datadogLogs } from '@datadog/browser-logs';

export const datadogFunctions = () => {
    const sampleRate =
        NODE_ENV.startsWith('prod') || NODE_ENV.startsWith('dev') || NODE_ENV.startsWith('cert')
            ? 100
            : 0;

    datadogLogs.init({
        clientToken: 'puba17597af99eead240a4e0d181ab5db9e',
        site: 'datadoghq.com',
        service: 'refer-a-friend-fe',
        env: `${NODE_ENV.replace('-cpe', '')}`,
        version: `${PRODUCT}-${VERSION}`,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: 'all',
        sampleRate: sampleRate,
    });
};
