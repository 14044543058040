import TagManager from 'react-gtm-module';

export const tagFunctions = () => {
    const prodTagManagerArgs = {
        gtmId: 'GTM-M3C2ZG8',
    };

    const qaTagManagerArgs = {
        gtmId: 'GTM-M3C2ZG8',
        auth: 'uFqB7nG68714fNEWxc_dVg',
        preview: 'env-3',
    };

    TagManager.initialize(
        NODE_ENV.startsWith('prod') ? prodTagManagerArgs : qaTagManagerArgs
    );
};
