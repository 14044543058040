import styled, { keyframes } from 'styled-components';

const Container = styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Image = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const opacityAnimation = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 0.25;
    }`;

const Disc = styled.div`
    background-color: ${({ theme }) => theme.colors.buttonGradient};
    width: 8px;
    height: 8px;
    border-radius: 50%;
    animation-name: ${opacityAnimation};
    animation-duration: 0.6s;
    animation-iteration-count: infinite;
    animation-play-state: running;
`;

const DiscOne = styled(Disc)`
    animation-delay: 0s;
`;
const DiscTwo = styled(Disc)`
    animation-delay: 0.2s;
    margin-left: 10px;
`;
const DiscThree = styled(Disc)`
    animation-delay: 0.4s;
    margin-left: 10px;
`;

export const Loading = () => {
    return (
        <Container>
            <Image>
                <DiscOne />
                <DiscTwo />
                <DiscThree />
            </Image>
        </Container>
    );
};
