import { DefaultTheme } from 'styled-components';
import BasketBall from '../assets/icons/sbk/BasketBall.svg';
import Envelope from '../assets/icons/sbk/Envelope.svg';
import Error from '../assets/icons/sbk/Error.svg';
import LeftDots from '../assets/icons/sbk/LeftDots.svg';
import Logo from '../assets/icons/sbk/Logo.svg';
import RightDots from '../assets/icons/sbk/RightDots.svg';
import {
    black,
    fanduelBlue,
    fanduelBlueD1,
    fanduelGreen,
    fanduelGreenD1,
    fanduelGreenD2,
    fanduelGreenL1,
    fanduelGrey,
    fanduelGreyD3,
    fanduelGreyD4,
    fanduelGreyL2,
    fanduelGreyL3,
    fanduelGreyL6,
    fanduelGreyL5,
    fanduelNavy,
    fanduelRedD2,
    fanduelWhite,
} from './colors';
import { sportsbookText } from './sportsbookText';

export let sportsbook: DefaultTheme;
sportsbook = {
    name: 'sportsbook',
    fontFamily: "'ProximaNova', Arial",
    colors: {
        pageBg: fanduelGreyL6,
        contentBg: fanduelWhite,
        containerBg: fanduelGreyL5,
        headerBg: fanduelNavy,
        containerBorder: fanduelGreyL2,
        inviteBorder: fanduelBlueD1,
        headerText: fanduelNavy,
        defaultText: fanduelGreyD4,
        progressText: fanduelGreyD3,
        buttonText: fanduelWhite,
        linkText: fanduelBlueD1,
        buttonGradient: fanduelBlue,
        successTextDark: fanduelGreenD2,
        successTextLight: fanduelGreen,
        successIcon: fanduelGreen,
        successIconDark: fanduelGreenD1,
        successIconLight: fanduelGreenL1,
        warningText: fanduelRedD2,
        neutralLine: fanduelGrey,
        topSectionText: fanduelNavy,
        topSectionThemeBg: fanduelGreyL6,
        termsAndConditionsText: fanduelBlueD1,
        headerLogoBg: fanduelNavy,
        joinFanduelBtn: `linear-gradient(
            180deg,
            ${fanduelGreenL1} 0%,
            ${fanduelGreenD1} 100%
            );`,
        stateSelectorDetail: fanduelGreyL2,
        stateSelectorArrow: black,
        disabledButton: fanduelGreyL3,
        disabledButtonText: fanduelGreyD3,
    },
    mediaQueries: {
        extraLarge: {
            maxWidth: '1024px',
        },
        large: {
            maxWidth: '960px',
        },
        medium: {
            maxWidth: '640px',
        },
        small: {
            maxWidth: '375px',
        },
        extraSmall: {
            maxWidth: '320px',
        },
    },
    defaultText: {
        text: {
            size: '14px',
            weight: 'normal',
            boldText: 'normal',
            extraSmallSize: '',
            textDecoration: '',
            whiteSpaceSmall: 'normal',
            whiteSpaceLarge: 'normal',
        },
    },
    icons: {
        main: Envelope,
        noReferral: BasketBall,
        error: Error,
        leftDots: LeftDots,
        logo: Logo,
        rightDots: RightDots,
        landingGraphic: Envelope,
    },
    text: sportsbookText,
    header: {
        text: {
            size: '20px',
            weight: 'bold',
            extraSmallSize: '',
            boldText: 'normal',
            textDecoration: '',
            whiteSpaceSmall: 'normal',
            whiteSpaceLarge: 'normal',
        },
    },
    subTitle: {
        text: {
            size: '16px',
            weight: 'normal',
            extraSmallSize: '15px',
            boldText: 'normal',
            textDecoration: '',
            whiteSpaceSmall: 'pre-wrap',
            whiteSpaceLarge: 'normal',
        },
    },
    welcome: {
        text: {
            size: '36px',
            weight: 'normal',
            extraSmallSize: '',
            boldText: 'normal',
            textDecoration: '',
            whiteSpaceSmall: 'normal',
            whiteSpaceLarge: 'normal',
        },
    },
    link: {
        text: {
            size: '14px',
            weight: 'normal',
            extraSmallSize: '',
            boldText: 'normal',
            textDecoration: '',
            whiteSpaceSmall: 'normal',
            whiteSpaceLarge: 'normal',
        },
    },
    borderRadius: {
        horizontalPadding: {
            radius: '0',
        },
    },
    overflow: {
        imageDiv: {
            isHidden: 'hidden',
        },
        home: {
            isHidden: 'hidden',
        },
    },
    margins: {
        topSection: {
            row: {
                margin: '0',
                medium: '',
            },
            rowSmall: {
                margin: '0',
                medium: '',
            },
            column: {
                margin: '0',
                medium: '',
            },
            outer: {
                margin: '0',
                medium: '0 0 -16px 0',
            },
            imageDiv: {
                small: {
                    margin: '0 -165px 0 0',
                    medium: '',
                },
                extraSmall: {
                    margin: '0 -190px 0 0',
                    medium: '',
                },
                medium: {
                    margin: '0 -115px 0 0',
                    medium: '',
                },
                maxWidth: '248px',
                maxHeight: '224px',
            },
            styledSubTitle: {
                margin: '0',
                medium: '',
            },
            topMarginSpacing: {
                extraSmall: '8px',
                small: '16px',
                medium: '25px',
                large: '8px',
                extraLarge: '20px',
                maxSpacing: '28px',
            },
        },
        headerSection: '15px 0 0 0',
        landingPage: 11,
    },
    paddings: {
        column: {
            large: '0',
            small: '0',
        },
        horizontalPadding: {
            large: '16px',
            small: '0',
        },
        errorPagePadding: {
            large: '10% 0',
            small: '0 0',
        },
    },
    scale: {
        medium: '1',
        small: '',
        extraSmall: '',
    },
    dimensions: {
        width: '167px',
        subtitleMaxWidth: '245px',
        errorPageHeight: '100vh',
    },
    position: '',
    positionType: '',
    positionTop: '',
};
