import { RewardSplit } from '../../lib/types/OfferModel';

export const determineAsymmetric = (
    sbkReferrerRewardAmount: number,
    sbkRefereeRewardAmount: number,
    casinoReferrerRewardAmount: number,
    casinoRefereeRewardAmount: number,
    offerType: string
) => {
    switch (offerType) {
        case 'SPORTSBOOK':
            if (sbkReferrerRewardAmount !== sbkRefereeRewardAmount) {
                return true;
            }
            return false;
        case 'CASINO':
            if (casinoReferrerRewardAmount !== casinoRefereeRewardAmount) {
                return true;
            }
            return false;
        default:
            return false;
    }
};

export const determineRewardSplitType = (
    sbkReferrerRewardAmount: number,
    sbkRefereeRewardAmount: number,
    casinoReferrerRewardAmount: number,
    casinoRefereeRewardAmount: number,
    rewardType: string
): RewardSplit => {
    if (rewardType === 'PRICE_BOOST_REWARD') return 'profitBoost';
    if (casinoRefereeRewardAmount === 0 && sbkRefereeRewardAmount === 0) {
        return 'zeroRecruit';
    }
    if (
        casinoReferrerRewardAmount === casinoRefereeRewardAmount &&
        sbkReferrerRewardAmount === sbkRefereeRewardAmount
    ) {
        return 'symmetric';
    }
    return 'asymmetric';
};
